import { Alert } from "react-native";
import { firestore, auth, storage, functions } from "utils/firebase";
import { getUsersFromMembersPromise, getUsersPromise } from "./user-service";
import { logout } from "./auth-service";
import { getOrgPromise } from "./organizations-service";

export const generateInviteLink = async (orgId) => {
	// get date a week from today
	const date = new Date();
	date.setDate(date.getDate() + 7);

	const inviteId = await firestore.collection("inviteLinks").add({
		expires: date,
		orgId
	});
	// console.log('generate invite link', inviteId.id)
	return `https://invite.rayzeapp.com/invite-0?invite=${inviteId.id}`;
};

export const checkInviteLinkExists = async (orgId) => {
	const invite = await firestore
		.collection("inviteLinks")
		.where("orgId", "==", orgId)
		.get();
	if (invite.empty) {
		return false;
	} else {
		return `https://invite.rayzeapp.com/invite-0?invite=${invite.docs[0].id}`;
	}
};

export const deleteInviteLink = async (orgId) => {
	const invite = await firestore
		.collection("inviteLinks")
		.where("orgId", "==", orgId)
		.get();
	invite.docs.forEach(async (doc) => {
		await firestore.collection("inviteLinks").doc(doc.id).delete();
	});
};

export const getInvite = async (inviteId) => {
	return firestore
		.collection("inviteLinks")
		.doc(inviteId)
		.get()
		.then(async (doc) => {
			if (doc.exists) {
				const invite = {
					id: doc.id,
					...doc.data()
				};

				const org = await firestore
					.collection("organizations")
					.doc(invite.orgId)
					.get();
				invite.org = { id: org.id, ...org.data() };

				return invite;
			} else {
				return null;
			}
		});
};

export const generateInvitePasscode = async (email) => {
	//generate 6 digit passcode
	const passcode = Math.floor(100000 + Math.random() * 900000);
	console.log(passcode);
	//store passcode in browser local storage
	localStorage.setItem("invitePasscode", passcode);

	sendPasscodeEmail(passcode, email);
};

export const checkInvitePasscode = async (inviteId, passcode) => {
	const invite = await getInvite(inviteId);
	if (invite) {
		const passcodes = invite.passcodes;
		const validPasscode = passcodes.find(
			(p) => p.passcode === parseInt(passcode)
		);
		if (validPasscode) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
};

//send passcode email via cloud functions
export const sendPasscodeEmail = async (passcode, email) => {
	functions
		.httpsCallable("sendPasscode")({ passcode, email })
		.then((result) => {
			console.log(result);
		})
		.catch((error) => {
			console.log(error);
		});
};
